<template>
  <div>
    <img :src="downloadImg" width="100%" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["downloadImg"]),
  },
  mounted() {
    this.$toast("长按保存图片");
  },
};
</script>
